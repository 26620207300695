var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('FormulateForm',{attrs:{"name":_vm.name},on:{"submit-raw":_vm.handleSubmitRaw},model:{value:(_vm._value),callback:function ($$v) {_vm._value=$$v},expression:"_value"}},[_c('v-card',_vm._b({attrs:{"elevation":"0"}},'v-card',_vm.cardProps,false),[_c('v-card-text',{class:[_vm.$vuetify.breakpoint.mobile ? 'px-0' : 'px-2', 'text--primary']},[(_vm.showRequiredHelp && _vm.editMode)?_c('div',{staticClass:"px-2 mt-n2",staticStyle:{"position":"absolute","right":"8px"}},[_c('span',{staticClass:"mr-2 red--text"},[_vm._v("*")]),_vm._v(" 必須項目 ")]):_vm._e(),(_vm.skeletonLoading)?_c('v-row',{class:[_vm.$vuetify.breakpoint.mobile ? 'px-3' : '']},_vm._l((6),function(p_key){return _c('v-col',{key:`p_${p_key}`,attrs:{"cols":"12"}},[_c('v-skeleton-loader',{staticClass:"text-h6",attrs:{"type":"heading","width":"20em"}}),_c('v-row',_vm._l((4),function(c_key){return _c('v-col',{key:`c_${c_key}`,attrs:{"cols":"6"}},[_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line"}})],1)}),1)],1)}),1):_vm._l((_vm.layout.sections),function(section){return _c('form-section',_vm._b({key:section.id,scopedSlots:_vm._u([{key:"bottom_title",fn:function(){return [_vm._t(section.label)]},proxy:true},{key:"bottom_row",fn:function(){return [_vm._t(section.label + '_bottom')]},proxy:true}],null,true)},'form-section',{
          title: section.label,
          editHeading: section.editHeading,
          detailHeading: section.detailHeading,
          editMode: _vm.editMode,
          hidden: _vm.hiddenSectionNames.indexOf(section.label) > -1,
          notShow: _vm.notShowSectionNames.indexOf(section.label) > -1,
        },false),[_c('SectionColumn',{attrs:{"object-info":_vm.objectInfo,"edit-mode":_vm.editMode,"handle-change-value":_vm.handleChangeValue,"section":section,"readonly-field-names":_vm.readonlyFieldNames,"custom-validations":_vm.customValidations}})],1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }