<template>
  <v-footer
    v-if="!isDeleted"
    v-show="!hidden"
    outlined
    style="position: sticky; bottom: 0px; z-index: 3;"
    elevation="5"
    class="d-print-none"
  >
    <div class="flex text-center">
      <template v-if="!referenceOnly && canEdit">
        <template v-if="editMode">
          <div v-if="!disable.includes('cancel')" class="d-inline-flex">
            <Confirm
              message="入力内容を破棄します。よろしいですか？"
              @on-click-yes="$emit('cancel')"
            >
              <template #default="{ on }">
                <ActionButton
                  btn-value="キャンセル"
                  depressed
                  @click="on.click"
                />
              </template>
            </Confirm>
          </div>
          <ActionButton
            v-if="!disable.includes('save')"
            btn-value="保存"
            icon="mdi-content-save"
            color="primary"
            depressed
            @click="$emit('save')"
          />
          <v-tooltip left :disabled="$vuetify.breakpoint.smAndDown">
            <template #activator="{ on }">
              <ActionButton
                v-if="!disable.includes('attachment')"
                icon="mdi-paperclip"
                color="primary"
                elevation="2"
                outlined
                fab
                right
                fixed
                :parent-on="on"
                :style="{
                  bottom: $vuetify.breakpoint.smAndDown ? '74px' : '16px',
                  'background-color': 'white',
                }"
                @click="$emit('add-attachment')"
              />
            </template>
            <span>ファイルを添付</span>
          </v-tooltip>
        </template>
        <template v-else>
          <ActionButton
            v-if="!disable.includes('edit') && canEdit"
            btn-value="編集"
            icon="mdi-file-edit"
            color="primary"
            outlined
            @click="$emit('edit')"
          />
          <Confirm
            v-if="useVersion && !disable.includes('fix')"
            :message="fixMessage"
            @on-click-yes="$emit('fix')"
          >
            <template #default="{ on }">
              <ActionButton
                :btn-value="fixText"
                icon="mdi-check"
                :disabled="isFixed"
                color="primary"
                elevation="0"
                @click="on.click"
              />
            </template>
          </Confirm>

          <Confirm
            v-if="!disable.includes('remove')"
            :message="`${deleteText}します。よろしいですか？`"
            @on-click-yes="$emit('remove')"
          >
            <template #default="{ on }">
              <ActionButton
                :btn-value="deleteText"
                :icon="
                  deleteText == '削除' ? 'mdi-delete' : 'mdi-delete-forever'
                "
                color="error"
                text
                dark
                :style="deleteBtnStyle"
                :class="{ 'float-end': $vuetify.breakpoint.xs }"
                @click="on.click"
              />
            </template>
          </Confirm>
        </template>
      </template>
      <template v-else-if="referenceOnly">
        <ActionButton
          v-if="canEdit && !disable.includes('next-version')"
          btn-value="続報登録"
          icon="mdi-file-edit"
          color="primary"
          outlined
          @click="$emit('next-version')"
        />
      </template>
    </div>
  </v-footer>
</template>
<script>
import ActionButton from '@/components/detail/ActionButton';
import Confirm from '@/components/common/Confirm';
export default {
  components: {
    ActionButton,
    Confirm,
  },
  props: {
    canEdit: { type: Boolean, required: true },
    referenceOnly: { type: Boolean, required: true },
    useVersion: { type: Boolean, required: true },
    editMode: { type: Boolean, required: true },
    isFixed: { type: Boolean, default: false },
    deleteText: { type: String, default: () => '削除' },
    fixMessage: { type: String, default: '確定します。よろしいですか？' },
    fixText: { type: String, default: '確定' },
    // 無効なボタンリスト
    disable: {
      type: Array,
      default: () => [],
    },
    isDeleted: { type: Boolean, required: true },
    hidden: { type: Boolean, default: false },
  },
  computed: {
    // 削除・取消ボタンは誤操作防止のため右寄せにする
    // モバイル画面は画面幅の制約があるのでfloat-endクラスを設定
    deleteBtnStyle() {
      return this.$vuetify.breakpoint.smAndUp
        ? { position: 'absolute', right: '16px' }
        : '';
    },
  },
};
</script>
