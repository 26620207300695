<template>
  <FormulateForm v-model="_value" :name="name" @submit-raw="handleSubmitRaw">
    <v-card v-bind="cardProps" elevation="0">
      <v-card-text
        :class="[$vuetify.breakpoint.mobile ? 'px-0' : 'px-2', 'text--primary']"
      >
        <!-- FUJISAN連携項目説明
        <div class="px-2 my-n2 text-caption">
          ※印はFUJISAN連携対象項目です。
        </div> -->
        <!-- 必須項目表示 -->
        <div
          v-if="showRequiredHelp && editMode"
          class="px-2 mt-n2"
          style="position: absolute; right: 8px;"
        >
          <span class="mr-2 red--text">*</span>
          必須項目
        </div>
        <!-- スケルトンローダ表示 -->
        <v-row
          v-if="skeletonLoading"
          :class="[$vuetify.breakpoint.mobile ? 'px-3' : '']"
        >
          <v-col v-for="p_key of 6" :key="`p_${p_key}`" cols="12">
            <v-skeleton-loader type="heading" width="20em" class="text-h6" />
            <v-row>
              <v-col v-for="c_key of 4" :key="`c_${c_key}`" cols="6">
                <v-skeleton-loader type="list-item-two-line" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- フォームセクション表示 -->
        <form-section
          v-for="section in layout.sections"
          v-else
          :key="section.id"
          v-bind="{
            title: section.label,
            editHeading: section.editHeading,
            detailHeading: section.detailHeading,
            editMode,
            hidden: hiddenSectionNames.indexOf(section.label) > -1,
            notShow: notShowSectionNames.indexOf(section.label) > -1,
          }"
        >
          <SectionColumn
            :object-info="objectInfo"
            :edit-mode="editMode"
            :handle-change-value="handleChangeValue"
            :section="section"
            :readonly-field-names="readonlyFieldNames"
            :custom-validations="customValidations"
          >
          </SectionColumn>
          <template #bottom_title>
            <slot :name="section.label" />
          </template>
          <template #bottom_row>
            <slot :name="section.label + '_bottom'" />
          </template>
        </form-section>
      </v-card-text>
    </v-card>
  </FormulateForm>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapActions } from 'vuex';
import { LayoutInfomation } from '.';

import formSection from './section/index.vue';
import SectionColumn from './section/SectionColumn.vue';

export default Vue.extend({
  components: { formSection, SectionColumn },
  props: {
    /**
     * フォーム名
     */
    name: { type: String, required: true },
    /**
     * レイアウト情報
     */
    layout: { type: Object as PropType<LayoutInfomation>, required: true },
    /**
     * 非表示セクション
     */
    hiddenSectionNames: { type: Array, required: false, default: () => [] },
    /**
     * 非表示項目
     */
    hiddenFieldNames: { type: Array, required: false, default: () => [] },
    /**
     * ？非表示項目(v-showで制御)
     */
    notShowSectionNames: { type: Array, required: false, default: () => [] },
    /**
     * 変更禁止セクション
     */
    readonlySectionNames: { type: Array, required: false, default: () => [] },
    /**
     * 変更禁止項目
     */
    readonlyFieldNames: { type: Array, required: false, default: () => [] },
    /**
     * 入力チェック関数
     */
    customValidations: { type: Array, required: false, default: () => [] },
    /**
     * オブジェクト情報
     */
    objectInfo: { type: Object, required: true },
    /**
     * 編集モード
     */
    editMode: { type: Boolean },
    /**
     * スケルトンローディング中
     */
    skeletonLoading: { type: Boolean },
    /**
     * フォームデータ
     */
    value: { type: Object, default: () => ({}) },
    /**
     * 必須項目の表示をするか
     */
    showRequiredHelp: { type: Boolean, default: true },
    /**
     * カードのプロパティ
     */
    cardProps: { type: Object, default: () => ({}) },
  },
  computed: {
    _value: {
      get(): Object {
        return this.value;
      },
      set(val: Object) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    handleChangeValue(event: unknown) {
      this.$emit('change', event);
    },
    handleSubmitRaw(form: unknown) {
      this.$emit('submit-raw', form);
    },
  },

  ...mapActions('snackbar', ['openErrorSnackBar']),
});
</script>
