<template>
  <v-btn v-bind="$attrs" class="ml-4" v-on="parentOn" @click="action">
    <v-icon v-if="icon" :left="$attrs.fab === undefined">
      {{ icon }}
    </v-icon>
    <span>{{ btnValue }}</span>
  </v-btn>
</template>
<script>
export default {
  props: {
    btnValue: String,
    icon: String,
    parentOn: Object,
  },
  methods: {
    action(event) {
      this.$emit('click', event);
    },
  },
};
</script>
