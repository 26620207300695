<template>
  <v-col :cols="cols">
    <template v-if="fieldPath !== '__empty__'">
      <!-- 非表示 -->
      <div v-if="hidden" style="height: 0px;" />
      <!-- スペース表示 -->
      <div v-else-if="blank" style="height: 64px;" />
      <!-- 本表示 -->
      <v-row v-else>
        <v-col class="d-flex">
          <!-- 必須項目表示 -->
          <span v-if="required && editMode" class="mr-1 ml-n1 red--text">
            *
          </span>
          <span v-else class="mr-2" />
          <!-- 入力表示部 -->
          <div style="flex: 1; white-space: pre-line;">
            <!-- オブジェクト定義を使わないとき用のスロット -->
            <!-- <slot v-if="$scopedSlots.default" /> -->
            <FormulateInput
              v-bind="{ ...vueformulateIntput, vueformulateIntput }"
              v-on="{ input }"
            >
              <template #label>
                <span />
              </template>
            </FormulateInput>
          </div>
        </v-col>
      </v-row>
    </template>
  </v-col>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue';
import { isNumber } from 'lodash';
import { VueformulateIntputProps, FieldInfomation } from './type';
import { columnSizeDef } from './columnSize';

export default Vue.extend({
  props: {
    /**
     * trueがセットされた場合表示されなくなります。
     */
    hidden: { type: Boolean, required: false },
    /**
     *trueがセットされた場合、入力項目を表示せずに空の項目を表示します。
     */
    blank: { type: Boolean, required: false },
    /**
     * trueがセットされた場合、編集モードになります。
     */
    editMode: { type: Boolean, required: false },
    /**
     * trueがセットされた場合に必須マークを表示します。
     */
    required: { type: Boolean, required: false },
    /**
     * trueがセットされた場合項目を編集不可にします。
     */
    readonly: { type: Boolean, required: false },
    /**
     * 項目定義情報
     */
    fieldInfo: {
      type: Object as PropType<FieldInfomation>,
      required: false,
      default: (): FieldInfomation | null => null,
    },
    /**
     * 項目名
     */
    fieldPath: { type: String, required: false, default: '' },
    /**
     * カラム分割数
     */
    column: { type: [String, Number, Boolean], required: false, default: '1' },
    /**
     * validationカスタム項目
     * eg. { validation: ['matches', /[0-9]/], validationMessages: { matches : '数値以外入力できません' } }
     */
    customValidation: {
      type: [Object, null] as PropType<{
        fieldName: string;
        value: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          validation: string[];
          validationRules: Record<
            string,
            ({ value }: { value: any }) => boolean
          >;
          validationMessages: Record<string, string>;
        };
      } | null>,
      required: false,
      default: null,
    },
    /**
     * FormulateInputに橋渡しするProp
     */
    inputProps: { type: Object, default: () => ({}) },
  },
  computed: {
    /**
     * inputFieldにわたすプロパティ
     */
    vueformulateIntput: {
      cache: false,
      get(): VueformulateIntputProps {
        const validation = [];
        // 必須項目チェック
        if (this.required) validation.push(['required']);
        // カスタムチェック
        if (this?.customValidation?.value?.validation) {
          validation.push(...this.customValidation.value.validation);
        }
        // 項目有効判定
        let disabled = false;
        // 編集不可モードチェック
        disabled = disabled || this.readonly;
        // 非編集モードは編集不可
        disabled = disabled || !this.editMode;
        // deepなオブジェクトは編集不可
        disabled = disabled || this.fieldPath.split('.').length > 1;
        if (this.fieldInfo) {
          return {
            type: this.fieldInfo.type,
            label: this.fieldInfo.label,
            items: this.fieldInfo.picklistValues,
            name: this.fieldPath,
            fieldPath: this.fieldPath,
            fieldRequired: this.required,
            disabled,
            validation: validation.join('|'),
            validationRules: this.customValidation?.value?.validationRules,
            validationMessages: this.customValidation?.value
              ?.validationMessages,
            validationName: this.fieldInfo.label,
            originType: this.fieldInfo.originType,
            multiple: this.fieldInfo.multiple,
            anyOf: this.fieldInfo.anyOf,
            hint: this.fieldInfo.helpText,
            reference:
              this.fieldInfo.$ref ||
              (this.fieldInfo.items as { $ref: unknown })?.$ref,
            reverseGeocodingEventhandler: this.fieldInfo
              .reverseGeocodingEventhandler,
            picklistController: this.fieldInfo.picklistController,
            defaultValue: this.fieldInfo.default,
            handler: this.fieldInfo.handler,
            notApplyDefaultValue: this.fieldInfo.notApplyDefaultValue,
            autocomplete: 'off',
            ...this.fieldInfo.inputProps,
            ...this.inputProps,
          };
        } else {
          return { type: '' };
        }
      },
    },
    /**
     * 表示するカラム設定
     */
    cols(): any {
      if (this.$vuetify.breakpoint.mobile) {
        return '12';
      }
      if (!isNumber(this.column) && typeof this.column !== 'string') {
        return this.column;
      }
      const index = 12 / Number(this.column);
      return (columnSizeDef as any)[index][this.$vuetify.breakpoint.name];
    },
  },
  // async mounted() {
  //   // 項目情報を取得
  //   if (this.objectName)
  //     this.objectInfo = await this.$util.getObjectInfo(this.objectName);
  // },
  methods: {
    input(value: any) {
      const event = {
        fieldPath: this.fieldPath,
        value,
      };
      this.$emit('input', event);
    },
  },
});
</script>
<style>
.dense .row {
  max-width: 20em;
  min-width: 20em;
  margin-right: 1em;
}
</style>
