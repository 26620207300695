<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <v-card>
    <v-toolbar dense color="primary" dark flat>
      <v-toolbar-title>
        <v-icon left> mdi-comment-text-multiple </v-icon>コメント
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="() => $emit('showCommentDialog', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-actions v-if="canPost" class="pt-4">
      <div style="flex: 1;" class="pr-5">
        <v-textarea
          v-model="inputText"
          height="100px"
          outlined
          hide-details
          label="コメント"
          placeholder="コメントを入力してください。"
        />
      </div>
      <div>
        <v-btn color="primary" @click="postComment">
          <v-icon left> mdi-send </v-icon>送信
        </v-btn>
      </div>
    </v-card-actions>
    <v-card-actions v-else class="pt-4">
      <span>コメントを投稿するには最新報を開いてください。</span>
    </v-card-actions>
    <v-list three-line>
      <template v-for="(item, index) in items">
        <v-divider :key="index" />
        <v-list-item
          :key="item.Id"
          :class="{ 'text-end': userId == item.CreatedById }"
        >
          <v-list-item-avatar v-if="userId != item.CreatedById" color="primary">
            <v-img
              v-if="_.get(item, 'CreatedBy.Avatar')"
              :src="_.get(item, 'CreatedBy.Avatar')"
            />
            <v-icon v-else dark>
              mdi-account
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <span class="text-body-1">
                {{ _.get(item, 'CreatedBy.Name') || '[削除されたユーザー]' }}
              </span>
              <span class="ml-2 grey--text text--lighten-1 text-body-2">
                {{ item.CreatedDate | datetime }}
              </span>
            </v-list-item-title>
            <div
              :class="{
                'text-body-2': true,
                'grey--text': true,
                'text--darken-2': true,
                'mt-1': true,
                'text-start': true,
                'pl-10': userId == item.CreatedById,
                'pr-10': userId != item.CreatedById,
              }"
              style="white-space: pre-line;"
              v-html="
                Autolinker.link(item.Body, {
                  phone: false,
                  sanitizeHtml: true,
                })
              "
            ></div>
          </v-list-item-content>
          <v-list-item-avatar v-if="userId == item.CreatedById" color="success">
            <v-img
              v-if="_.get(item, 'CreatedBy.Avatar')"
              :src="_.get(item, 'CreatedBy.Avatar')"
            />
            <v-icon v-else dark>
              mdi-account
            </v-icon>
          </v-list-item-avatar>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue';
import { provided } from '..';
import { Autolinker } from 'autolinker';
export default Vue.extend({
  inject: ['objectName', 'reactive'],
  data: () => ({
    items: [] as {
      /**
       * @label=カスタムオブジェクト ID
       */
      Id: string;
      /**
       * @label=対象オブジェクトID
       */
      ObjectId: string;
      /**
       * @label=対象オブジェクト名
       */
      ObjectName: string;
      /**
       * @label=本文
       */
      Body: string | null;
      /**
       * @label=削除
       */
      IsDeleted: boolean | null;
      CreatedById: string | null;
      LastModifiedById: string | null;
      /**
       * @label=最終更新日
       */
      LastModifiedDate: Date | null;
      /**
       * @label=作成日
       */
      CreatedDate: Date;
    }[],
    inputText: '',
    Autolinker,
  }),
  computed: {
    canPost(): boolean {
      const { reactive } = this as any;
      return (
        reactive.value?.Version__c === '0' ||
        reactive.latest?.Id === reactive.value?.Id
      );
    },
    userId() {
      return this.$store.state.user.user.Id;
    },
  },
  watch: {
    items: {
      handler(to) {
        this.$emit('updated-comments', to);
      },
      deep: true,
    },
  },
  async mounted() {
    await this.load();
  },
  methods: {
    /**
     * コメントを投稿する
     */
    async postComment() {
      const { controller } = (this as any).$pageProperty;
      const { reactive, objectName } = (this as unknown) as provided;
      await this.$store.dispatch(
        'loading/register',
        this.$con.invoke({
          controller,
          method: 'postComment',
          params: {
            data: {
              Body: this.inputText,
              ObjectId: reactive.ParentId__c,
              ObjectName: objectName,
            },
            value: reactive.value,
            currentUrl: location.href,
            createdUserId: this.$store.state.user.user.Id
          },
        }),
      );
      await this.$store.dispatch('loading/register', this.load());
      this.inputText = '';
    },
    async load() {
      const { controller } = (this as any).$pageProperty;
      const { reactive, objectName } = this as any;
      this.items = await this.$store.dispatch(
        'loading/register',
        this.$con.invoke({
          controller,
          method: 'getComment',
          params: {
            ObjectId: reactive.ParentId__c,
            ObjectName: objectName,
            // LastModifiedDate: !this.canPost && reactive.value.LastModifiedDate,
          },
        }),
      );
    },
  },
});
</script>
