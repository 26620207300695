<template>
  <v-responsive class="overflow-y-auto" style="max-height: 450px;">
    <div v-html="prettyHtml" />
  </v-responsive>
</template>
<script>
import { html as diffHtml } from 'diff2html';
import 'diff2html/bundles/css/diff2html.min.css';

export default {
  props: {
    // 左側表示用項目
    compareFrom: {
      type: Object,
      required: true,
    },
    // 右側表示用項目
    compareTo: {
      type: Object,
      required: true,
    },
    //表示ラベル項目
    fieldInfo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    // 比較のテキスト
    diffs: '',
  }),
  computed: {
    prettyHtml() {
      return diffHtml(this.diffs, {
        drawFileList: true,
        matching: 'lines',
        outputFormat: 'side-by-side',
        diffStyle: 'char',
      });
    },
  },

  methods: {
    async load() {
      // 差異がなければ処理を続けない
      if (_.isEqual(this.compareFrom, this.compareTo)) {
        this.diffs = '';
        return;
      }
      // 差異がある場合はdiff文字列を生成する
      this.diffs = this.getDiffString();
    },
    // diff文字列を取得する
    getDiffString() {
      // 項目の情報と項目セットを取り出し
      const { fields = [], fieldInfo = {} } = this.fieldInfo || {};

      // 表示する項目を構成
      const fieldsInfo = fields.map((v) => ({
        ...v,
        fieldInfo: fieldInfo[v.fieldPath],
      }));

      // nilなら空テキストを返す関数
      const nilToEmpty = (input) => (_.isNil(input) ? '' : input);

      // 各項目の比較の結果
      const diffArray = fieldsInfo.map((field) => {
        let fromValue = this.compareFrom[field.fieldPath];
        let toValue = this.compareTo[field.fieldPath];

        // 項目の種類によって見分け
        if (field.type === 'datetime') {
          // 日時の場合はフォーマットして追加
          fromValue = this.$dateFns.fnsFormat(fromValue, '', 'P HH:mm');
          toValue = this.$dateFns.fnsFormat(toValue, '', 'P HH:mm');
        } else if (field.type === 'reference') {
          // 参照の場合はNameを追加
          fromValue = this.compareFrom[field.fieldInfo.relationshipName].Name;
          toValue = this.compareTo[field.fieldInfo.relationshipName].Name;
        }

        // gitのdiffに合わせたテキスト出力
        if (fromValue == toValue) {
          return `\n ${field.label}:\n  ${nilToEmpty(toValue)}`;
        } else {
          return `\n ${field.label}:\n- ${nilToEmpty(
            fromValue,
          )}\n+ ${nilToEmpty(toValue)}`;
        }
      });
      return `--- 比較\n+++ 比較\n@@  @@\n${diffArray.join('\n')}`;
    },
  },
  watch: {
    compareFrom: {
      handler() {
        this.load();
      },
      deep: true,
    },
    compareTo: {
      handler() {
        this.load();
      },
      deep: true,
    },
    fieldInfo: {
      handler() {
        this.load();
      },
      deep: true,
    },
  },
};
</script>
