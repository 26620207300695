<template>
  <v-dialog v-model="dialog" scrollable max-width="500">
    <template #activator="{ on }">
      <ActionButton
        btn-value="移管"
        icon="mdi-transfer-right"
        outlined
        v-on="on"
      />
    </template>
    <v-card class="pa-2">
      <v-card-title class="mb-2">
        <v-icon>mdi-transfer-right</v-icon>
        この情報を他の組織に移管する
      </v-card-title>
      <v-card-subtitle class="text--primary">
        移管先の組織を選択してください
      </v-card-subtitle>
      <v-form ref="form" v-model="valid" lazy-validation @submit="validate">
        <v-card-text class="py-0 px-2">
          <v-autocomplete
            v-model="newOrg"
            :items="organizations"
            outlined
            dense
            item-text="Name"
            item-value="Id"
            :rules="[rules.required]"
            label="移管先"
          ></v-autocomplete>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!valid"
            color="info"
            class="mr-4"
            depressed
            @click="validate"
          >
            移管
          </v-btn>
          <v-btn class="mr-4" depressed @click="() => (dialog = false)">
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import ActionButton from '@/components/detail/ActionButton.vue';

export default {
  name: 'DetailTransfer',

  components: {
    ActionButton,
  },

  props: {
    objectName: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      default: null,
    },
    nextPage: {
      type: String,
      default: null,
    },
    orgFieldNames: {
      type: Array,
      default: () => ['CDS_M_Organization__c'],
    },
  },

  data: () => ({
    dialog: false,
    valid: true,
    // 移管先
    newOrg: null,
    rules: {
      required: (v) => !!v || '必須項目です',
    },
    organizations: [],
  }),

  watch: {
    dialog(to, from) {
      if (to && !from) {
        this.loadOrganization();
      }
    },
  },

  methods: {
    async loadOrganization() {
      if (this.organizations.length !== 0) return;
      // 組織マスタの取得
      const res = await this.$store.dispatch(
        'loading/register',
        this.$con.invoke({
          controller: 'CDS_CTR_Common',
          method: 'getOrganizations',
        }),
      );
      this.organizations = res;
    },

    // バリデート
    async validate() {
      if (!this.$refs.form.validate()) {
        return;
      }
      try {
        if (!this.value?.Id) {
          throw new Error('データがありません。');
        }
        // 移管
        await this.$store.dispatch(
          'loading/register',
          this.$con.invoke({
            controller: this.$pageProperty.controller,
            method: 'transferData',
            params: {
              objectName: this.objectName,
              Id: this.value.Id,
              orgId: this.newOrg,
              orgFieldNames: this.orgFieldNames,
            },
          }),
        );
        this.$transition.to(this.nextPage, {
          ...this.$query.current(),
          sb: JSON.stringify({
            message: '移管しました',
            props: {
              color: 'green',
              bottom: true,
              timeout: 2000,
            },
            closable: true,
          }),
        });
      } catch (error) {
        this.openSnackBar({
          message: `移管できませんでした。${this.$t(error.message)}`,
          props: {
            color: 'red',
            bottom: true,
            timeout: 10000,
          },
          closable: true,
        });
      }
    },

    ...mapActions('snackbar', ['openSnackBar']),
  },
};
</script>
