export const columnSizeDef = {
  '1': {
    md: '1',
    lg: '1',
    xl: '1',
  },
  '2': {
    md: '2',
    lg: '2',
    xl: '2',
  },
  '3': {
    md: '3',
    lg: '3',
    xl: '3',
  },
  '4': {
    md: '4',
    lg: '4',
    xl: '4',
  },
  '6': {
    md: '6',
    lg: '6',
    xl: '6',
  },
  '12': {
    md: '12',
    lg: '12',
    xl: '12',
  },
};
