var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',[_c('v-toolbar',{attrs:{"dense":"","color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-comment-text-multiple ")]),_vm._v("コメント ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":() => _vm.$emit('showCommentDialog', false)}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.canPost)?_c('v-card-actions',{staticClass:"pt-4"},[_c('div',{staticClass:"pr-5",staticStyle:{"flex":"1"}},[_c('v-textarea',{attrs:{"height":"100px","outlined":"","hide-details":"","label":"コメント","placeholder":"コメントを入力してください。"},model:{value:(_vm.inputText),callback:function ($$v) {_vm.inputText=$$v},expression:"inputText"}})],1),_c('div',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.postComment}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-send ")]),_vm._v("送信 ")],1)],1)]):_c('v-card-actions',{staticClass:"pt-4"},[_c('span',[_vm._v("コメントを投稿するには最新報を開いてください。")])]),_c('v-list',{attrs:{"three-line":""}},[_vm._l((_vm.items),function(item,index){return [_c('v-divider',{key:index}),_c('v-list-item',{key:item.Id,class:{ 'text-end': _vm.userId == item.CreatedById }},[(_vm.userId != item.CreatedById)?_c('v-list-item-avatar',{attrs:{"color":"primary"}},[(_vm._.get(item, 'CreatedBy.Avatar'))?_c('v-img',{attrs:{"src":_vm._.get(item, 'CreatedBy.Avatar')}}):_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-account ")])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm._.get(item, 'CreatedBy.Name') || '[削除されたユーザー]')+" ")]),_c('span',{staticClass:"ml-2 grey--text text--lighten-1 text-body-2"},[_vm._v(" "+_vm._s(_vm._f("datetime")(item.CreatedDate))+" ")])]),_c('div',{class:{
              'text-body-2': true,
              'grey--text': true,
              'text--darken-2': true,
              'mt-1': true,
              'text-start': true,
              'pl-10': _vm.userId == item.CreatedById,
              'pr-10': _vm.userId != item.CreatedById,
            },staticStyle:{"white-space":"pre-line"},domProps:{"innerHTML":_vm._s(
              _vm.Autolinker.link(item.Body, {
                phone: false,
                sanitizeHtml: true,
              })
            )}})],1),(_vm.userId == item.CreatedById)?_c('v-list-item-avatar',{attrs:{"color":"success"}},[(_vm._.get(item, 'CreatedBy.Avatar'))?_c('v-img',{attrs:{"src":_vm._.get(item, 'CreatedBy.Avatar')}}):_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-account ")])],1):_vm._e()],1)]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }