<template>
  <v-dialog
    v-model="__showUploaderModal"
    scrollable
    max-width="1024"
    :persistent="attachmentUploading"
  >
    <v-card
      class="mx-auto"
      max-width="1024"
      outlined
      @dragenter.stop.prevent="dragging++"
      @dragleave.stop.prevent="dragging--"
      @dragover.stop.prevent
      @drop.prevent="handleDrop"
    >
      <v-overlay
        v-if="!attachmentUploading"
        :absolute="true"
        :value="dragging > 0"
        :opacity="0.92"
        light
        :dark="false"
        color="#fce4ec"
      >
        <span>ファイルをドロップして添付</span>
      </v-overlay>
      <v-app-bar>
        <v-toolbar-title>
          <v-icon left>
            mdi-paperclip
          </v-icon>
          {{
            attachmentUploading ? 'ファイルアップロード' : '新規添付ファイル'
          }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          v-if="!attachmentUploading"
          color="primary"
          outlined
          @click="addAttachment()"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          <span>ファイル追加</span>
        </v-btn>
        <v-toolbar-title v-else class="text-body-1">
          時間がかかることがありますがそのままお待ちください。
        </v-toolbar-title>
      </v-app-bar>
      <v-card-text style="overflow-y: scroll;">
        <v-list two-line subheader>
          <v-list-item v-if="attachments.length === 0">
            <div
              style="
                display: flex;
                align-content: center;
                justify-content: center;
                width: 100%;
              "
            >
              <v-icon left>
                mdi-information
              </v-icon>
              添付ファイルはありません
            </div>
          </v-list-item>
          <v-list-item v-for="(attachment, i) in attachments" :key="i">
            <v-list-item-avatar>
              <FileTypeIcon
                large
                :type="(attachment.Type || attachment.file.type).split('/')[0]"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ attachment.Name || attachment.file.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ attachment.Type || attachment.file.type }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ (attachment.Size || attachment.file.size) | filesize }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <!-- プログレスバー -->
            <div v-if="attachmentUploading" class="d-flex flex-grow-1">
              <v-progress-linear
                v-model="attachment.progres"
                stream
                height="15"
              >
                <span class="white--text">
                  {{
                    attachment.progres !== undefined
                      ? Math.ceil(attachment.progres)
                      : 100
                  }}%
                </span>
              </v-progress-linear>
            </div>
            <v-list-item-icon v-if="!attachmentUploading">
              <v-btn icon color="red" @click="removeAttachment(i)">
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider />
      <v-card-actions v-if="!attachmentUploading">
        <v-btn class="ma-1 ml-3" @click="__showUploaderModal = false">
          <v-icon left>
            mdi-window-close
          </v-icon>
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileTypeIcon from '@/components/common/FileTypeIcon';

export default {
  components: { FileTypeIcon },
  props: {
    showUploaderModal: { type: Boolean, default: false },
    attachmentUploading: { type: Boolean, default: false },
    attachments: { type: Array, default: () => [] },
  },
  data: () => ({
    dragging: 0,
  }),
  computed: {
    __showUploaderModal: {
      get() {
        return this.$props.showUploaderModal;
      },
      set(value) {
        this.$emit('show', value);
      },
    },
  },
  methods: {
    removeAttachment(i) {
      this.$emit('remove-attachment', i);
    },
    addAttachment() {
      this.$emit('add-attachment');
    },
    handleDrop(e) {
      this.dragging = 0;
      if (this.attachmentUploading) return;
      const files = e.dataTransfer.files;
      if (files.length === 0) return;
      this.$emit('dropped-files', files);
    },
  },
};
</script>
