<template>
  <v-card>
    <v-card-text class="black--text">
      <template v-if="showDiff">
        <v-row justify="center">
          <v-col cols="6" sm="4" md="2" lg="1">
            <v-select
              v-model="compareFromVer"
              :items="versions"
              label="報"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="6" sm="4" md="2" lg="1">
            <v-select
              v-model="compareToVer"
              :items="versions"
              label="報"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
        </v-row>
        <CompareByJson
          :compareFrom="compareFrom"
          :compareTo="compareTo"
          :fieldInfo="compareFieldSet"
        />
      </template>
      <div v-else class="text-center">
        履歴情報が1報しかないため、比較できません。
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import CompareByJson from './CompareByJson';

export default {
  components: {
    CompareByJson,
  },
  props: {
    // オブジェクト名
    objectName: { type: String, required: true },
    // レコード取得メソッド名
    getRecordsMethodName: { type: String, default: 'getRecords' },
    // 検索条件デフォルト値
    defaultSearchCondition: { type: Object, default: () => ({}) },
    // 比較用項目セット名
    fieldSet: {
      type: String,
      default: null,
    },
  },

  data: (v) => ({
    // 履歴データ
    list: [],
    // 比較fromver
    compareFromVer: null,
    // 比較tover
    compareToVer: null,
    // バージョン
    versions: [],
    // 比較用項目セット
    compareFieldSet: {},
    // diffを表示するか
    showDiff: false,
  }),
  computed: {
    compareFrom() {
      // バージョン情報からデータを取得
      return this.list.find((v) => v.Version__c == this.compareFromVer) || {};
    },
    compareTo() {
      return this.list.find((v) => v.Version__c == this.compareToVer) || {};
    },
  },
  async mounted() {
    await Promise.all([this.initial(), this.load()]);
  },
  methods: {
    async initial() {
      const { fieldSet } = await this.getFieldSet(
        this.objectName,
        this.fieldSet,
      );
      this.compareFieldSet = fieldSet;
    },
    async getFieldSet(objectName, fieldSetName) {
      return await this.$store.dispatch(
        'loading/register',
        this.$util.getFieldSet(objectName, fieldSetName),
      );
    },
    async load() {
      // 履歴情報の取得
      const res = await this.$store.dispatch(
        'loading/register',
        this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: this.getRecordsMethodName,
          params: {
            objectName: this.objectName,
            condition: JSON.stringify(this.defaultSearchCondition),
            listOptions: JSON.stringify({ sortBy: ['Version__c'] }),
            listProperty: JSON.stringify({ mode: null }),
            disasterId: null,
          },
        }),
      );
      console.log('res ', res);

      this.list = res.records;
      this.showDiff = res.totalSize > 1;

      // 履歴が1件以上あれば、値のセットをする
      if (this.showDiff) {
        this.setValue();
      }
    },
    async setValue() {
      // バージョンの配列を取り出し
      this.versions = this.list.map((v) => v.Version__c);

      this.$nextTick(() => {
        // toverには最新バージョンを指定
        this.compareToVer = _.max(this.versions);
        // fromverには最新バージョン-1(>0)を指定
        this.compareFromVer = _.max([this.compareToVer - 1, 1]);
      });
    },
  },
};
</script>
