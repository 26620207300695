<template>
  <v-row v-show="!notShow" v-if="!hidden" class="mt-0">
    <v-col v-if="showSectionTitle" cols="12">
      <!-- セクションタイトル -->
      <v-card-title v-if="title" class="title d-flex align-center">
        <div class="primary white--text py-1 px-2 px-md-4 text-subtitle-1">
          {{ title }}
        </div>
        <v-spacer style="border-bottom: thin solid #1976d2;"></v-spacer>
      </v-card-title>
      <v-col cols="12" :class="title ? 'px-0' : 'px-0 pt-0'">
        <!-- タイトル下部スロット -->
        <slot name="bottom_title" />
        <!-- 入力フィールド表示部 -->
        <div>
          <slot />
        </div>
        <!-- セクション下部スロット -->
        <slot name="bottom_row" />
      </v-col>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  props: {
    /**
     * セクションタイトル
     */
    title: { type: String, required: false, default: '' },
    /**
     * trueの場合、編集時にセクションタイトルを表示します
     */
    editHeading: { type: Boolean, required: false, default: true },
    /**
     * trueの場合、非編集時にセクションタイトルを表示します
     */
    detailHeading: { type: Boolean, required: false, default: true },
    /**
     * trueがセットされた場合、編集モードになります
     */
    editMode: { type: Boolean, required: false, default: null },
    /**
     * trueの場合、セクションを非表示にします
     */
    hidden: { type: Boolean, required: false, default: false },
    /**
     * trueの場合、セクションを一時的に非表示にします
     */
    notShow: { type: Boolean, required: false, default: false },
  },
  computed: {
    /**
     * trueの場合、セクションタイトルを表示します。
     */
    showSectionTitle(): boolean {
      return (
        (this._editMode && this.editHeading) ||
        (!this._editMode && this.detailHeading)
      );
    },
    _editMode(): boolean {
      return this.editMode;
    },
  },
});
</script>
<style scoped>
.title {
  padding: 0 8px !important;
}
/** 2列で上から下へのレイアウトを選択した場合、横並びで全項目を表示する */
.dense {
  display: flex !important;
  flex-wrap: wrap !important;
}
</style>
