import utility from '@/plugins/utility';

/**
 * レイアウト情報を取得する
 * @param {*} param0
 */
export async function getLayout({ layoutName, objectName }) {
  try {
    const { layout, objectInfo } = await utility.getLayout(
      objectName,
      layoutName,
    );
    // デフォルト値の設定
    // オブジェクト定義によるデフォルト値を設定する
    const initVal = Object.entries(objectInfo.properties)
      .map(([fieldPath, fieldInfo]) => ({ fieldPath, fieldInfo }))
      .filter((f) => f.defaultValue)
      .reduce((prev, { fieldPath, fieldInfo }) => {
        return {
          ...prev,
          [fieldPath]: fieldInfo.defaultValue,
        };
      }, {});
    return { layout, objectInfo, initVal };
  } catch (e) {
    console.error(e);
    throw new Error('レイアウト情報の読み出しに失敗しました。' + e.message);
  }
}

/**
 * Id等の識別子を削除して新規にInsertできる形にする
 * @param {*} obj
 */
export function deleteIdColumn(obj) {
  delete obj.Id;
  delete obj.attributes;
  delete obj.CreatedDate;
  delete obj.LastModifiedDate;
}
